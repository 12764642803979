<template>
    <div>
        <div v-if="isHeader" class="mb-1 px-4">
            <div class="text-truncate text-uppercase subtitle-2 font-weight-bold">{{ $trans(title) }}</div>
            <div v-if="subtitle" class="body-2">{{ $trans(subtitle) }}</div>
        </div>
        <v-card>
            <settings-list-items
                :class="settingsListItemsClasses"
                :items="items"
            />
        </v-card>
        <div v-if="hint" class="body-2 px-4 mt-1">{{ $trans(hint) }}</div>
    </div>
</template>

<script>
import SettingsListItems from '@apps/settings/components/SetingsList/SettingsListItems'
export default {
    name: 'SettingsListGroup',
    props: {
        title: {
            type: String,
            default: undefined
        },
        subtitle: {
            type: String,
            default: undefined
        },
        hint: {
            type: String,
            default: undefined
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        access: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    components: { SettingsListItems },
    computed: {
        isHeader() {
            return this.title || this.subtitle
        },
        settingsListItemsClasses() {
            const classes = []

            if (this.isHeader) {
                classes.push('pt-0')
            }

            if (this.items.length === 1) {
                // classes.push('py-0')
            }

            return classes
        }
    }
}
</script>

<style lang=scss>

</style>
