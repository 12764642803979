<template>
    <v-list
        v-if="itemsComputed.length > 0"
        flat
    >
        <v-list-item-group
            color="primary"
        >
            <template
                v-for="(item, index) in itemsComputed"
            >
                <v-divider
                    :key="`v-divider-${index}`"
                    v-if="index > 0"
                />
                <settings-list-item
                    :key="`settings-list-item-${index}`"
                    v-bind="item"
                />
            </template>
        </v-list-item-group>
    </v-list>
</template>

<script>
import SettingsListItem from '@apps/settings/components/SetingsList/SettingsListItem'
export default {
    name: 'SettingsListItems',
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    components: { SettingsListItem },
    computed: {
        itemsComputed() {
            return this.items.filter(o => {
                if(!o.access) return true

                return this.$auth.hasAccess(o.access)
            })
        }
    }
}
</script>

<style lang=scss>

</style>
