<template>
    <v-list-item
        :to="to"
        v-on="on"
    >
        <v-list-item-icon v-if="icon">
            <w-icon
                :value="icon"
                :color="color"
            />
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{ $trans(title) }}</v-list-item-title>
            <v-list-item-subtitle v-if="subtitle">{{ $trans(subtitle) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <div class="d-flex align-center">
                <small v-if="action" class="mr-2">
                    {{ $trans(computedAction) }}
                </small>
                <w-icon value="NEXT"/>
            </div>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'SettingsListItem',
    props: {
        access: {
            type: Object
        },
        title: {
            type: String,
            required: true
        },
        to: {
            type: Object,
            default: undefined
        },
        on: {
            type: Object,
            default: undefined
        },
        subtitle: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        color: {
            type: String,
            default: 'primary'
        },
        action: {
            type: [ String, Function ],
            default: undefined
        }
    },
    computed: {
        computedAction() {
            if (typeof this.action === 'function') {
                return this.action(this)
            } else {
                return this.action
            }
        }
    }
}
</script>

<style lang=scss>

</style>
